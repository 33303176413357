import React from 'react';
import { observer } from 'mobx-react';

import { isClient } from 'bu.helpers';
import storeFactory from '../factories/store.factory';
import wireframeService from '../services/wireframe.service';
import navigationService from '../services/navigation.service';
import PAGES from '../constants/pages.constant';

import { setNonStickyFooter, setStickyFooter, setElementVisibility, findManyByClassName }
  from '../helpers/wireframe.helper';

const visibleFooterLinksForApp = ['Kontakt', 'AGB', 'Datenschutz', 'Impressum'];

type Props = {
  stickyFooter?: boolean;
}

@observer
export default class Wireframe extends React.Component<Props> {

  ssoStore = storeFactory.getSsoStore();

  static defaultProps = {
    stickyFooter: false
  };

  _updateWireframe() {
    if (!isClient()) {
      return;
    }

    const { pathname } = navigationService.getCurrentLink();

    this._setFooterLinksVisibility(false);
    this._addFooterLinksAttriutes();
    wireframeService.updateWireframeAccountController({
      ssoUser: this.ssoStore.ssoUserData, expiredSessionData: this.ssoStore.expiredSessionData });
    wireframeService.updateAccountLinks();

    if (PAGES.THANK_YOU === pathname) {
      wireframeService.showMyListsMenu(this.ssoStore.isLoggedIn);
    } else {
      wireframeService.hideMyListsMenu(this.ssoStore.isLoggedIn);
    }

    if (PAGES.THANK_YOU_ERROR === pathname || this.props.stickyFooter) {
      setStickyFooter();
    } else {
      setNonStickyFooter();
    }
  }

  _addFooterLinksAttriutes() {
    const footerLinksList = Array.from(findManyByClassName('c24m-footer-list'))[0];
    const footerLinks = Array.prototype.slice.call<HTMLCollectionOf<HTMLAnchorElement>, [], HTMLAnchorElement[]>(
      footerLinksList.getElementsByTagName('a')
    );

    footerLinks.forEach(footerLink => {
      footerLink.setAttribute('deviceoutput', 'app');
      footerLink.setAttribute('displaymode', 'blank');
    });
  }

  _setFooterLinksVisibility(visible: boolean) {
    const footerLinksList = Array.from(findManyByClassName('c24m-footer-list'))[0];
    const footerLinks = Array.prototype.slice.call<HTMLCollectionOf<HTMLAnchorElement>, [], HTMLAnchorElement[]>(
      footerLinksList.getElementsByTagName('a')
    );

    footerLinks.forEach(footerLink => {
      if (visibleFooterLinksForApp.indexOf(footerLink.text.replace(/[^A-z]/g, '')) === -1) {
        setElementVisibility(footerLink, visible);
      }
    });
  }

  render() {
    this._updateWireframe();
    return null;
  }
}

