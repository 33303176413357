import { initChatty } from 'bu.helpers';
import { DEVICE_OUTPUT, PRODUCT, PRODUCT_VERSICHERUNGSCENTER, ValueOf } from 'bu.lookups';
import { webAppLoggingService } from 'bu.logger-client';
import configService from '../services/config.service';

const DEFAULT_CHAT_FLOW_ENTRY = 'start_Default::bu';

const PAGE_TO_CHAT_FLOW_ENTRY = new Map<string, `${string}::${ValueOf<typeof PRODUCT_VERSICHERUNGSCENTER>}`>([
  ['/appointment', 'start_appointment::bu'],
  ['/comparison', 'start_comparison::bu'],
  ['/onboarding-additional-data', 'start_onboarding_additionalData::bu'],
  ['/onboarding-coverage-data', 'start_onboarding_coverageData::bu'],
  ['/onboarding-personal-data', 'start_onboarding_personalData::bu'],
  ['/onboarding-summary', 'start_onboarding::bu'],
  ['/personal-data', 'start_personalData::bu'],
  ['/personal-login', 'start_personalLogin::bu'],
  ['/result', 'start_result::bu'],
  ['/thankyou', 'start_thankYou::bu'],
  ['/thankyou-error', 'start_thankYou::bu'],
]);

function getChatFlowEntry(path: string): string {
  return PAGE_TO_CHAT_FLOW_ENTRY.get(path) || DEFAULT_CHAT_FLOW_ENTRY;
}

function getChattyProduct(product?: ValueOf<typeof PRODUCT>): ValueOf<typeof PRODUCT_VERSICHERUNGSCENTER> {
  if (product === PRODUCT.DUV) {
    return PRODUCT_VERSICHERUNGSCENTER.DUV;
  }
  if (product === PRODUCT.GF) {
    return PRODUCT_VERSICHERUNGSCENTER.GF;
  }
  return PRODUCT_VERSICHERUNGSCENTER.BU;
}

export function initChattyPerPage({
  deviceOutput,
  pathname,
  product,
}: {
  deviceOutput: ValueOf<typeof DEVICE_OUTPUT>;
  pathname: string;
  product?: ValueOf<typeof PRODUCT>;
}): void {
  const isCustomChattyEnabled = configService.getConfig().featureSwitches.isCustomChattyEnabled;
  const chatFlowEntry = isCustomChattyEnabled ? getChatFlowEntry(pathname) : 'start_wireframe_presale::bu';
  initChatty(
    {
      ...configService.getConfig().chatty,
      chatFlowName: isCustomChattyEnabled ? 'v_bu.live' : 'vv.live',
      deviceOutput,
      chatFlowEntry,
      clientDetails: {
        product: getChattyProduct(product),
        chatFlowEntry,
      },
    },
    webAppLoggingService,
  );
}
