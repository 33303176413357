import { isClient } from 'bu.helpers';

type PageViewEventProps = {
  pid: string|null;
  areaId: string;
  event: string;
  adgt1?: string;
}

function triggerGoogleTagManagerPageView(pid: string|null, areaId: string, adgt1: string|undefined) {
  if (!isClient()) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  const pageViewEvent: PageViewEventProps = {
    pid: pid,
    areaId: areaId,
    event: 'SpaPageView'
  };

  if (adgt1) {
    pageViewEvent.adgt1 = adgt1;
  }

  window.dataLayer.push(pageViewEvent);
}

export {
  triggerGoogleTagManagerPageView
};
