import { StringsOnlyQuery } from '../types/strings-only-query';

export type UtmTrackingVars = {
  wishlistEntry?: boolean;
}

export function getUtmTrackingVars(queryParams: StringsOnlyQuery): UtmTrackingVars | null {
  const utmSource = queryParams.utm_source;

  if (utmSource === 'wishlist') {
    return { wishlistEntry: true };
  }

  return null;
}
