import configService from '../services/config.service';

// Doc: https://confluence.check24.de/pages/viewpage.action?spaceKey=C24MOCO&title=How+To%3A+Generate+Adjust+Links
export function getAdjustLinkToAppStore() {
  const adjustBaseUrl = 'https://app.adjust.com';
  const tracker = 'lpbw9le';
  const fallback = configService.getConfig().buMobileAppUrl;

  const query = new URLSearchParams({
    // Note: window.location.search is already URL-encoded so, we encode twice the BU-URL's query params.
    // Potentially in old or broken browsers, that first encoding is broken
    deep_link: `c24-app-vergleiche://${window.location.host}${window.location.pathname}${window.location.search}`,
    fallback,
  });

  return `${adjustBaseUrl}/${tracker}?${query}`;
}

