import { setCookie } from 'cookies-next';
import APP_SPLASH_COOKIE_FIELD from '../constants/app-splash-cookie-field.constant';

export function setAppSplashSeen() {
  setCookie(APP_SPLASH_COOKIE_FIELD, '1', {
    path: '/',
    secure: true,
    sameSite: 'lax',
  });
}
