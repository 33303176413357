import { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';
import { setAppSplashSeen } from '../helpers/app-splash-overlay.helper';
import APP_SPLASH_COOKIE_FIELD from '../constants/app-splash-cookie-field.constant';
import uaParserService from '../services/ua-parser.service';
import { getAdjustLinkToAppStore } from '../helpers/adjust-link.helper';

export function useAppSplashOverlay(isApp: boolean, skipAppSplashLayer: boolean) {
  const [isLongScreen, setIsLongScreen] = useState(false);
  const [renderOverlay, setRenderOverlay] = useState(false);
  const [appDownloadLink, setAppDownloadLink] = useState('');

  useEffect(() => {
    if (skipAppSplashLayer || isApp || getCookie(APP_SPLASH_COOKIE_FIELD) || uaParserService.isHuaweiDevice()) {
      setRenderOverlay(false);
      return;
    }
    setRenderOverlay(true);
    setIsLongScreen(window.screen.height > 580);
    setAppDownloadLink(getAdjustLinkToAppStore());
  }, [isApp, skipAppSplashLayer]);

  const closeOverlay = () => {
    setAppSplashSeen();
    setRenderOverlay(false);
  };

  return {
    isLongScreen,
    renderOverlay,
    closeOverlay,
    appDownloadLink,
  };
}
