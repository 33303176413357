import React, { useState } from 'react';
import { useSearchParams } from 'next/navigation';
import AppSplashCloseSvg from '../../../svgs/splash-close.svg';
import Button from '../button/button';
import configService from '../../../services/config.service';
import storeFactory from '../../../factories/store.factory';
import { useAppSplashOverlay } from '../../../hooks/app-splash-overlay.hook';

export default function AppSplashOverlay() {
  const deviceOutputStore = storeFactory.getDeviceOutputStore();
  const [backgroundImage, setBackgroundImage] = useState('');
  const imgSrcWEBP = '/app-splash-background.webp';
  const imgSrcPNG = '/app-splash-background-v2.png';
  const searchParams = useSearchParams();

  const setLandingPageCookieIframeUrl = configService.getConfig().appSplashCookieIframeUrl;
  const { isLongScreen, renderOverlay, closeOverlay, appDownloadLink } = useAppSplashOverlay(
    deviceOutputStore.isAppOrTabletApp(),
    // we added this query parameter in VERBU-8696, to prevent the splash screen from showing on some campaigns
    searchParams.get('splash') === '0',
  );

  if (!renderOverlay) {
    return null;
  }

  if (!backgroundImage) {
    return (
      <picture style={{ display: 'none' }}>
        <source type="image/webp" srcSet={imgSrcWEBP} />
        <img
          src={imgSrcPNG}
          onLoad={(e) => {
            setBackgroundImage((e.target as HTMLImageElement).currentSrc);
          }}
          alt="app splash background"
        />
      </picture>
    );
  }

  return (
    <div className="AppSplashOverlay" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <iframe title="AppSplashIframe" className="AppSplashOverlay-iframe" src={setLandingPageCookieIframeUrl} />
      <div className="AppSplashOverlay-wrapper">
        <AppSplashCloseSvg onClick={closeOverlay} className="AppSplashOverlay-close" />
        <div className="AppSplashOverlay-descriptionContainer">
          {isLongScreen && <div className="AppSplashOverlay-descriptionHeader">Schnell und einfach.</div>}
          <div className="AppSplashOverlay-description">Berufsunfähigkeitsversicherung abschließen und entspannen!</div>
          <div className="AppSplashOverlay-ctaContainer">
            <a href={appDownloadLink}>
              <Button className="AppSplashOverlay-downloadCta">gratis herunterladen</Button>
            </a>
            <div className="AppSplashOverlay-mobileStoreLabelsContainer">
              <a href={appDownloadLink}>
                <img
                  className="AppSplashOverlay-mobileStoreLabel"
                  alt="link to google play"
                  src="/google-play-store.svg"
                />
              </a>
              <a href={appDownloadLink}>
                <img className="AppSplashOverlay-mobileStoreLabel" alt="link to app store" src="/app-store.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
