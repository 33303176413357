export function shouldShowPointsGratificationBar(pathname: string, inCheck24PointsProgram: boolean): boolean {
  const notAllowPageToShow = [
    '/cashback/thank-you',
    '/cashback/[cashbackId]',
    '/appointment',
    '/appointment-confirmation'
  ];

  return !notAllowPageToShow.includes(pathname) && inCheck24PointsProgram;
}
