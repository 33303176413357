import React, { ReactNode } from 'react';
import Router, { withRouter, SingletonRouter } from 'next/router';
import { observer } from 'mobx-react';
import { GeneralTracking, GeneralTrackingPages } from 'bu.general-tracking';
import { SEARCH_DATA_FIELDS, NO_RESULTS_SPECIAL_TARIFF_ID, DEVICE_OUTPUT, ValueOf, PRODUCT } from 'bu.lookups';
import { PointsGratificationBarMobile } from 'bu.components-points-gratification-bar';
import { Mobile } from 'bu.components-customer-apps';
import { calculatePointsXtremePoints, shouldShowPointsGratificationBarForLinearPoints } from 'bu.helpers';
import { currency } from 'bu.formatters';
import { webAppLoggingService } from 'bu.logger-client';

import Wireframe from './wireframe';
import WireframeApp from './wireframe-app';

import storeFactory from './../factories/store.factory';
import { getUtmTrackingVars } from '../factories/utm-tracking-vars.factory';

import { isDUVapplicable } from '../helpers/duv.helper';
import { triggerGoogleTagManagerPageView } from '../helpers/google-tag-manager.helper';
import { shouldShowPointsGratificationBar } from '../helpers/page.helper';

import currentUrlService from '../services/current-url.service';
import configService from './../services/config.service';
import AppSplashOverlay from './common/app-splash-overlay/app-splash-overlay';
import b2bPartnerService from '../services/b2b-partner.service';
import setCookieService from '../services/set-cookie.service';
import navigationService from '../services/navigation.service';
import piwikService from './../services/piwik.service';

import { SearchData } from '../types/search-data';
import type { StringsOnlyQuery } from '../types/strings-only-query';
import type { Tariff } from '../types/tariff';
import { initChattyPerPage } from '../helpers/chatty.helper';

type Props = {
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  generalTrackingPage?: GeneralTrackingPages;
  shouldDeletePpsetCookie?: boolean;
  router: SingletonRouter;
  tariff?: Pick<Tariff, '_id' | 'netPrice'> | undefined;
  product?: ValueOf<typeof PRODUCT>;
};

const GRATIFICATION_BAR_PAGES = {
  notAllowPageToShow: ['/cashback/thank-you', '/cashback/[cashbackId]', '/appointment-confirmation'],

  nonPointsPages: [
    '/onboarding',
    '/onboarding-personal-data',
    '/onboarding-additional-data',
    '/onboarding-coverage-data',
    '/onboarding-summary-data',
    '/result',
    '/comparison',
    '/insurance-list',
  ],
};

@observer
class Page extends React.Component<Props> {
  searchDataStore = storeFactory.getSearchDataStore();
  ssoStore = storeFactory.getSsoStore();
  deviceOutputStore = storeFactory.getDeviceOutputStore();
  consultantCarouselStore = storeFactory.getConsultantCarouselStore();

  static defaultProps = {
    className: '',
    dataTestId: '',
    generalTrackingPage: undefined,
    shouldDeletePpsetCookie: false,
    tariff: undefined,
    product: PRODUCT.BU,
  };

  componentDidMount() {
    const { searchData } = this.searchDataStore;
    setCookieService.setXppSetCookie();
    setCookieService.setPpsetCookie();
    if (this.props.shouldDeletePpsetCookie) {
      setCookieService.deletePpsetCookie();
    }
    const queryParams = currentUrlService.getCurrentUrlQueryParams();
    this.trackCurrentPage(searchData, queryParams);
    this.triggerGoogleTagManager(searchData, queryParams);
    this.ssoStore.initUnifiedLoginEventListener();

    // deviceOutput is not always defined and always string type when reading from cookies
    const deviceOutput =
    (this.deviceOutputStore.deviceOutput as ValueOf<typeof DEVICE_OUTPUT>) || DEVICE_OUTPUT.MOBILE;
    initChattyPerPage({
      deviceOutput,
      pathname: this.props.router.pathname,
      product: this.props.product,
    });
  }

  triggerGoogleTagManager(searchData: SearchData, queryParams: StringsOnlyQuery) {
    const adgt1 = queryParams.adgt1;

    triggerGoogleTagManagerPageView(searchData.b2BAdPartner, `${Router.pathname.substring(1).toUpperCase()}bu`, adgt1);
  }

  trackCurrentPage(searchData: SearchData, queryParams: StringsOnlyQuery) {
    const isLoggedIn = this.ssoStore.isLoggedIn || false;
    const buRef = this._getBuRef();
    const consultants = this.consultantCarouselStore.consultants;
    const visibleConsultantId = consultants && consultants.length > 0 ? consultants[0].id : '';
    const utmTrackingVars = getUtmTrackingVars(queryParams);

    let duvVariables;
    if (isDUVapplicable(searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])) {
      duvVariables = [
        searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION],
        searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE],
        searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE],
        searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS],
        searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP],
        searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE],
      ].join('|');
    }

    piwikService.trackCurrentPage({
      ...searchData,
      isUserLoggedIn: isLoggedIn,
      buRef,
      visibleConsultantId,
      duvVariables,
      ...utmTrackingVars,
    });

    if (utmTrackingVars) {
      // VERBU-20964: Removing utm params to track only once
      navigationService.removeTrackingParamsFromQuery();
    }
  }

  _getBuRef() {
    const config = configService.getConfig();
    return config.buRef || '';
  }

  render() {
    const isApp = this.deviceOutputStore.isApp();
    const config = configService.getConfig();

    // we just want to exclude shouldDeletePpsetCookie from the props
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, dataTestId, shouldDeletePpsetCookie, generalTrackingPage, ...props } = this.props;

    const b2BAdPartner =
      this.searchDataStore.searchData?.b2BAdPartner || b2bPartnerService.getDefaultWpSet().b2BAdPartner;

    const pointsAmount = calculatePointsXtremePoints(
      this.props.tariff?.netPrice ?? 0,
      Number(this.props.tariff?._id) ?? NO_RESULTS_SPECIAL_TARIFF_ID,
      webAppLoggingService,
    );

    const isItPointsPage = this.props.tariff?.netPrice ? this.props.tariff?.netPrice > 0 : false;

    const showPointsGratificationBarForLinearPoints = shouldShowPointsGratificationBarForLinearPoints({
      pathname: this.props.router.pathname,
      inCheck24PointsProgram: this.ssoStore.inCheck24PointsProgram,
      tariff: this.props.tariff,
      notAllowPageToShow: GRATIFICATION_BAR_PAGES.notAllowPageToShow,
      nonPointsPages: GRATIFICATION_BAR_PAGES.nonPointsPages,
    });

    return (
      <React.Fragment>
        {config.featureSwitches.isLinearPointsXtremeEnabled
          ? showPointsGratificationBarForLinearPoints && (
            <Mobile.PointsGratificationBarMobile
              isItPointsPage={isItPointsPage}
              pointsAmount={pointsAmount}
              pointsAmountInEuros={currency.calculatePointsXtremeInEuros(pointsAmount)}
              termsOfServiceLink={config.termsOfServicePointsProgram}
            />
          )
          : shouldShowPointsGratificationBar(this.props.router.pathname, this.ssoStore.inCheck24PointsProgram) && (
            <PointsGratificationBarMobile isApp={isApp} termsOfServiceLink={config.termsOfServicePointsProgram} />
          )}
        {!isApp && <AppSplashOverlay />}
        <div {...props} data-test-id={dataTestId}>
          {children}
          {!isApp && <Wireframe />}
          {isApp && <WireframeApp />}
        </div>
        <GeneralTracking
          pageName={generalTrackingPage}
          b2BAdPartner={b2BAdPartner}
          xsSbu={this.searchDataStore.searchData?.xsSbu || ''}
          deviceOutput={this.deviceOutputStore.deviceOutput || 'mobile'}
          product="sbu"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Page);
